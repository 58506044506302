<script setup lang="ts">
import { installAntlerComponent, AntlerClasses } from "@/Utils/component";
import AppIcon, { IconName } from "@/Components/Shared/icon/AppIcon.vue";

export type BadgeAs = "div";
export type BadgeColor = "primary" | "dark" | "light" | "mtg" | "lorcana" | "pokemon";
export type BadgeSize = "small" | "regular";
export type BadgeVariant = "outlined";

type Props = {
    as?: BadgeAs;
    color?: BadgeColor;
    size?: BadgeSize;
    variant?: BadgeVariant;
    icon?: IconName;
};

const {
    as = "div",
    color = "primary",
    size = "regular",
    variant = undefined,
    icon = undefined,
} = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "flex items-center gap-1 rounded-full text-xs leading-none",
    variants: {
        size: {
            small: "px-1 leading-none text-[10px]",
            regular: "py-1 px-2",
        },
        color: {
            primary: "bg-primary text-white",
            dark: "bg-gray-700 text-white ",
            light: "bg-gray-200",
            mtg: "bg-red-100 text-red-800",
            lorcana: "bg-indigo-100 text-indigo-800",
            pokemon: "bg-yellow-100 text-yellow-800",
        },
        variant: {
            outlined: "bg-transparent",
        },
    },
};
const { aClass } = installAntlerComponent(
    "badge",
    { color, size, variant },
    classes,
);
</script>
<template>
    <transition name="fade">
        <component
            :is="as"
            :class="aClass()"
        >
            <AppIcon
                v-if="icon"
                :name="icon"
                size="xsmall"
            />

            <slot />
        </component>
    </transition>
</template>
